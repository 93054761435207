import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import { Container } from "../../Shared";

const query = graphql`
  {
    data: homesJson {
      sectionFive {
        title
        paragraphs
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
export function SectionFive() {
  const { data } = useStaticQuery(query);
  const { title, paragraphs, image } = data.sectionFive;
  const imagePlugin = getImage(image);

  return (
    <section>
      <Container>
        <div className="flex flex-col lg:flex-row gap-16 xl:gap-24 py-12 lg:py-20">
          <div className="lg:w-1/2 space-y-6 lg:order-2">
            <h3 className="text-lg md:text-xl font-bold uppercase text-white font-serif tracking-widest leading-loose md:leading-loose lg:leading-loose xl:leading-loose 2xl:leading-loose">
              {title}
            </h3>
            <div className="space-y-4">
              {Object.keys(paragraphs).map((key) => (
                <p key={key} className="text-base md:text-lg text-white">
                  {paragraphs[key]}
                </p>
              ))}
            </div>
          </div>
          <div className="lg:w-1/2 lg:flex lg:items-center">
            <div className="relative">
              <GatsbyImage image={imagePlugin} alt={title} />
              <svg
                className="absolute inset-0 translate-x-4 translate-y-4 -z-10"
                viewBox="0 0 768 332"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M766 2H2V330H634.353L766 203.083V2Z"
                  stroke="#1F2023"
                  strokeWidth="4"
                />
              </svg>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
