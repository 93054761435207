import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Container } from "../../Shared";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const query = graphql`
  {
    data: homesJson {
      sectionThre {
        title
        list {
          title
          total
        }
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  }
`;

export function SectionThre() {
  const { data } = useStaticQuery(query);
  const { title, list, image } = data.sectionThre;
  const pluginImage = getImage(image);

  return (
    <section>
      <Container>
        <div className="space-y-16 xl:space-y-24 py-12 lg:py-20">
          <h2 className="text-sm md:text-lg lg:text-xl uppercase text-white font-serif tracking-widest leading-loose md:leading-loose lg:leading-loose xl:leading-loose 2xl:leading-loose">
            {title}
          </h2>
          <div className="flex flex-col lg:flex-row xl:items-center gap-12 lg:gap-20">
            <div className="lg:w-1/2 flex items-center relative">
              <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 scale-125 md:scale-[2] lg:scale-150 w-72 h-72 rounded-full bg-gradient-to-tr from-red-400/50 to-red-500/50 blur-[200px]" />
              <GatsbyImage
                image={pluginImage}
                alt={title}
                className="md:mx-auto xl:w-[80%]"
              />
            </div>
            <div className="lg:w-1/2">
              <div className="space-y-4 flex flex-col items-center">
                {Object.keys(list).map((key, i) => (
                  <div key={key} className="relative">
                    <p
                      className={`text-4xl whitespace-nowrap text-white font-bold uppercase py-12 tracking-widest text-shadow before:content-[${list[key].total}]`}
                    >
                      {list[key].title}
                    </p>
                    <p
                      className={classNames(
                        "absolute -z-10 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-4xl font-bold uppercase tracking-widest text-outline",
                        i === list.length - 1
                          ? "text-6xl md:text-7xl"
                          : "text-8xl"
                      )}
                    >
                      {list[key].total}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
