import { Tab } from "@headlessui/react";
import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Container } from "../../Shared";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const query = graphql`
  {
    data: homesJson {
      sectionFour {
        title
        subtitle
        paragraphs
        tabs {
          title
          items
        }
      }
    }
  }
`;

export function SectionFour() {
  const { data } = useStaticQuery(query);
  const { title, subtitle, paragraphs, tabs } = data.sectionFour;

  return (
    <section>
      <Container>
        <div className="flex flex-col lg:flex-row gap-16 xl:gap-24 py-12 lg:py-20">
          <div className="lg:w-1/2 space-y-6">
            <h3 className="text-lg md:text-xl font-bold uppercase text-white font-serif tracking-widest leading-loose md:leading-loose lg:leading-loose xl:leading-loose 2xl:leading-loose">
              {title}
            </h3>
            <p className="uppercase text-sm text-white font-serif tracking-widest leading-loose">
              {subtitle}
            </p>
            <div className="space-y-4">
              {Object.keys(paragraphs).map((key) => (
                <p key={key} className="text-base md:text-lg text-white">
                  {paragraphs[key]}
                </p>
              ))}
            </div>
          </div>
          <div className="lg:w-1/2 space-y-6">
            <Tab.Group>
              <Tab.List className="p-2 bg-second border-2 border-third shadow-lg flex space-x-2">
                {Object.keys(tabs).map((key) => (
                  <Tab
                    key={key}
                    className={({ selected }) =>
                      classNames(
                        "px-3 py-3 md:py-4 lg:w-auto w-full font-serif text-[0.5rem] md:text-xs tracking-widest leading-loose uppercase transition-all ease-in-out duration-300",
                        selected
                          ? "bg-red-500"
                          : "text-white hover:bg-red-500/10"
                      )
                    }
                  >
                    {tabs[key].title}
                  </Tab>
                ))}
              </Tab.List>
              <Tab.Panels>
                {Object.keys(tabs).map((key) => (
                  <Tab.Panel key={key}>
                    <div className="relative bg-second shadow-lg">
                      <div className="absolute inset-0 border-2 border-third" />
                      <span className="absolute left-0 top-0 h-4 w-0.5 bg-red-500" />
                      <span className="absolute left-0 bottom-0 h-4 w-0.5 bg-red-500" />
                      <span className="absolute right-0 top-0 h-4 w-0.5 bg-red-500" />
                      <span className="absolute right-0 bottom-0 h-4 w-0.5 bg-red-500" />
                      <span className="absolute top-0 left-0 h-0.5 w-4 bg-red-500" />
                      <span className="absolute top-0 right-0 h-0.5 w-4 bg-red-500" />
                      <span className="absolute bottom-0 left-0 h-0.5 w-4 bg-red-500" />
                      <span className="absolute bottom-0 right-0 h-0.5 w-4 bg-red-500" />
                      <ul className="px-6 divide-y-2 divide-third">
                        {Object.keys(tabs[key].items).map((i) => (
                          <li
                            key={i}
                            className="py-6 flex space-x-3 items-baseline"
                          >
                            <svg
                              className="w-4 h-4 shrink-0"
                              viewBox="0 0 792 792"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                y="395.98"
                                width="560"
                                height="560"
                                transform="rotate(-45 0 395.98)"
                                fill="url(#paint0_linear_139_2)"
                              />
                              <defs>
                                <linearGradient
                                  id="paint0_linear_139_2"
                                  x1="280"
                                  y1="395.98"
                                  x2="280"
                                  y2="955.98"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stopColor="#ff5b5f" />
                                  <stop offset="1" stopColor="#ff141b" />
                                </linearGradient>
                              </defs>
                            </svg>
                            <p className="text-base lg:text-lg text-white">
                              {tabs[key].items[i]}
                            </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </Container>
    </section>
  );
}
