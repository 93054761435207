import React from "react";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { graphql, useStaticQuery } from "gatsby";
import { Container } from "../../Shared";
import {
  BinanceChainIcon,
  GooglePlayIcon,
  PolygonIcon,
  SteamStoreIcon,
  UnrealIcon,
} from "../../Icons";

const query = graphql`
  {
    data: homesJson {
      sectionOne {
        title
        description
        image {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              aspectRatio: 1.77
            )
          }
        }
      }
    }
  }
`;

export function SectionOne() {
  const { data } = useStaticQuery(query);
  const { title, description, image } = data.sectionOne;
  const pluginImage = getImage(image);

  return (
    <section>
      <BgImage image={pluginImage} className="relative">
        <Container>
          <div className="relative flex flex-col justify-center pt-44 pb-20 xl:py-0 xl:h-[45rem]">
            <div className="space-y-6 text-center">
              <div className="flex flex-col items-center text-center space-y-4 mx-auto md:max-w-lg">
                <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold uppercase text-white font-serif tracking-widest leading-loose md:leading-loose lg:leading-loose xl:leading-loose 2xl:leading-loose">
                  {title}
                </h1>
                <p className="text-xl md:text-2xl text-white">{description}</p>
              </div>
              <div className="space-y-4">
                {/* <Button>Download</Button> */}
                <span className="uppercase block font-mono text-overline tracking-[3px] text-sm">
                  AVAILABLE Q4 2024
                </span>
              </div>
            </div>
          </div>
        </Container>
        <div className="absolute inset-0 bg-gradient-to-t from-main via-main/5 to-main -z-10" />
      </BgImage>
      <div className="relative">
        <div className="absolute -top-32 w-full h-44 bg-gradient-to-t from-main" />
        <Container className="isolate">
          <div className="flex flex-col items-center xl:flex-row gap-20 xl:gap-12 pb-20">
            <div className="relative xl:w-3/5">
              <div className="absolute inset-0 border-2 border-third -translate-x-4 translate-y-4 -z-10" />
              <div className="grid grid-cols-2 md:grid-cols-5 w-full justify-center gap-y-12 py-6 md:py-8 lg:py-4 bg-second backdrop-blur-xl shadow-lg">
                <div className="col-span-1 flex justify-center items-center">
                  <div className="space-y-4 px-4">
                    <span className="block text-2xl text-center font-semibold">
                      50k +
                    </span>
                    <span className="block font-serif text-[0.625rem] tracking-widest leading-loose text-center">
                      community members
                    </span>
                  </div>
                </div>
                <div className="col-span-1 flex justify-center items-center border-l-2 border-l-third">
                  <div className="space-y-4 px-4">
                    <span className="block text-2xl text-center font-semibold">
                      1k
                    </span>
                    <span className="block font-serif text-[0.625rem] tracking-widest leading-loose text-center">
                      artworks
                    </span>
                  </div>
                </div>
                <div className="col-span-1 flex justify-center items-center lg:border-l-2 lg:border-l-third">
                  <div className="space-y-4 px-4">
                    <span className="block text-2xl text-center font-semibold">
                      13
                    </span>
                    <span className="block font-serif text-[0.625rem] tracking-widest leading-loose text-center">
                      Artists
                    </span>
                  </div>
                </div>
                <div className="col-span-1 flex justify-center items-center border-l-2 border-l-third">
                  <div className="space-y-4 px-4">
                    <span className="block text-2xl text-center font-semibold">
                      9
                    </span>
                    <span className="block font-serif text-[0.625rem] tracking-widest leading-loose text-center">
                      Developers
                    </span>
                  </div>
                </div>
                <div className="col-span-2 md:col-span-1 flex justify-center items-center lg:border-l-2 lg:border-l-third">
                  <div className="space-y-4 px-4">
                    <span className="block text-2xl text-center font-semibold">
                      32
                    </span>
                    <span className="block font-serif text-[0.625rem] tracking-widest leading-loose text-center">
                      Strong Team
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col xl:flex-row gap-12 xl:w-2/5">
              <div className="space-y-4 mx-auto text-center xl:w-1/2">
                <p className="uppercase text-xs font-serif text-white tracking-widest">
                  Powered by
                </p>
                <div className="flex space-x-4 justify-center">
                  <PolygonIcon className="h-10 w-10 lg:w-12 lg:h-12" />
                  <BinanceChainIcon className="h-12 w-12 lg:w-14 lg:h-14" />
                  <UnrealIcon className="h-10 w-10 lg:w-12 lg:h-12" />
                </div>
              </div>
              <div className="space-y-4 mx-auto text-center xl:w-1/2">
                <p className="uppercase text-xs font-serif text-white tracking-widest leading-loose">
                  available soon on mobile and pc
                </p>
                <div className="flex space-x-4 justify-center">
                  <SteamStoreIcon className="h-10 w-10 lg:w-12 lg:h-12" />
                  <GooglePlayIcon className="h-10 w-10 lg:w-12 lg:h-12" />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
}
