import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container, ModeCard } from "../../Shared";

const query = graphql`
  {
    data: homesJson {
      sectionSix {
        title
        overline
        description
        cards {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FIXED, width: 150)
            }
          }
        }
      }
    }
  }
`;

export function SectionSix() {
  const { data } = useStaticQuery(query);
  const { overline, title, description, cards } = data.sectionSix;

  return (
    <section>
      <Container>
        <div className="space-y-16 xl:space-y-24 py-12 lg:py-20">
          <div className="space-y-6 text-center lg:max-w-lg lg:mx-auto">
            <span className="uppercase block font-mono text-overline tracking-[3px] text-sm">
              {overline}
            </span>
            <h2 className="text-2xl md:text-3xl font-bold uppercase text-white font-serif tracking-widest leading-loose md:leading-loose lg:leading-loose xl:leading-loose 2xl:leading-loose">
              {title}
            </h2>
            <p className="text-lg md:text-xl text-white">{description}</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-6 gap-y-6">
            {Object.keys(cards).map((key) => (
              <ModeCard data={cards[key]} key={key} />
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
}
