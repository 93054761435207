import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Container } from "../../Shared";

const query = graphql`
  {
    data: homesJson {
      sectionSeven {
        title
        overline
        description
        secondTitle
        paragraphs
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  }
`;

export function SectionSeven() {
  const { data } = useStaticQuery(query);
  const { overline, title, description, secondTitle, paragraphs, image } =
    data.sectionSeven;
  const imagePlugin = getImage(image);

  return (
    <section>
      <Container>
        <div className="space-y-16 xl:space-y-24  py-12 lg:py-20">
          <div className="flex flex-col lg:flex-row gap-16 xl:gap-24">
            <div className="lg:w-1/2 space-y-12">
              <div className="space-y-6 text-center lg:text-left">
                <span className="uppercase block font-mono text-overline tracking-[3px] text-sm">
                  {overline}
                </span>
                <h2 className="text-2xl md:text-3xl font-bold uppercase text-white font-serif tracking-widest leading-loose md:leading-loose lg:leading-loose xl:leading-loose 2xl:leading-loose">
                  {title}
                </h2>
                <p className="text-lg md:text-xl text-white">{description}</p>
              </div>
              <div className="space-y-6">
                <h3 className="text-lg md:text-xl font-bold uppercase text-white font-serif tracking-widest leading-loose md:leading-loose lg:leading-loose xl:leading-loose 2xl:leading-loose">
                  {secondTitle}
                </h3>
                <div className="space-y-4">
                  {Object.keys(paragraphs).map((key) => (
                    <p key={key} className="text-base md:text-lg text-white">
                      {paragraphs[key]}
                    </p>
                  ))}
                </div>
              </div>
            </div>
            <div className="lg:w-1/2 lg:flex lg:items-center">
              <div className="relative">
                <GatsbyImage image={imagePlugin} alt={title} />
                <svg
                  className="absolute inset-0 translate-x-4 translate-y-4 -z-10"
                  viewBox="0 0 669 467"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M667 2H2V211.018V465H403.911L541.808 340.538L667 211.018V130.141V2Z"
                    stroke="#1F2023"
                    strokeWidth="4"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
