import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container } from "../../Shared";

const query = graphql`
  {
    data: homesJson {
      sectionTwo {
        title
        videoUrl
      }
    }
  }
`;

export function SectionTwo() {
  const { data } = useStaticQuery(query);
  const { title, videoUrl } = data.sectionTwo;

  return (
    <section>
      <Container>
        <div className="space-y-16 py-12 lg:py-20">
          <div className="text-center flex">
            <h2 className="text-sm md:text-lg lg:text-xl mx-auto uppercase text-white font-serif tracking-widest leading-loose md:leading-loose lg:leading-loose xl:leading-loose 2xl:leading-loose xl:max-w-4xl">
              {title}
            </h2>
          </div>
          <div className="relative p-4 bg-second shadow-lg">
            <div className="absolute inset-0 border-2 border-third -z-10" />
            <span className="absolute left-0 top-0 h-4 w-0.5 bg-red-500" />
            <span className="absolute left-0 bottom-0 h-4 w-0.5 bg-red-500" />
            <span className="absolute right-0 top-0 h-4 w-0.5 bg-red-500" />
            <span className="absolute right-0 bottom-0 h-4 w-0.5 bg-red-500" />
            <span className="absolute top-0 left-0 h-0.5 w-4 bg-red-500" />
            <span className="absolute top-0 right-0 h-0.5 w-4 bg-red-500" />
            <span className="absolute bottom-0 left-0 h-0.5 w-4 bg-red-500" />
            <span className="absolute bottom-0 right-0 h-0.5 w-4 bg-red-500" />
            <iframe
              src={videoUrl}
              title="Victory point trailer"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              className="aspect-video h-full w-full"
            />
          </div>
        </div>
      </Container>
    </section>
  );
}
